/** @jsx jsx */
import {
  Flex,
  Label,
  SemiTitle,
  Text,
} from '@bottlebooks/gatsby-design-system';
import { Link } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import { RichText } from '@bottlebooks/gatsby-theme-cms/src/components';
import GridImage from '@bottlebooks/gatsby-theme-cms/src/components/Blocks/GridImage';

export default function CardBlock({
  image,
  width,
  label,
  subtitle,
  title,
  _rawText,
  url,
}) {
  const link = url?.includes('http') ? { href: url } : { to: url };
  return (
    <Link {...link} sx={{ cursor: 'pointer' }}>
      <Flex
        direction="column"
        sx={{
          border: '1px solid border',
          '& p': { margin: 0 },
          '&:hover': { '& img': { opacity: 0.9 } },
        }}
      >
        <GridImage
          image={image}
          sx={{ marginBottom: 2, maxWidth: '100%' }}
          width={width}
          alt={title}
        />
        <Label sx={{ marginTop: 1 }}>{label}</Label>
        <SemiTitle
          // This is a SemiTitle "large" variant
          sx={{ fontSize: 28, paddingTop: 2 }}
        >
          {title}
        </SemiTitle>
        <Text sx={{ color: 'black' }}>{subtitle}</Text>
        {_rawText && (
          <React.Fragment>
            <Text sx={{ color: 'black' }}>
              <RichText>{_rawText}</RichText>
            </Text>
            {/* We should only show a link when there is a link */}
            {url && <Text>More...</Text>}
          </React.Fragment>
        )}
      </Flex>
    </Link>
  );
}

export const fragment = graphql`
  fragment SanityCardBlockFragment on SanityCardBlock {
    image {
      ...ImageWithPreview
    }
    label
    title
    subtitle
    _rawText
    url
  }
`;
